import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi everyone, I'm <span className="purple">Hayden Nester</span>.
            <br />
            I am currently employed as a Senior Technical Account Manager, but
            <br />
            I have spent my life building computers, exploring new technologies,
            and following advancements in the tech industry.
            <br />
            Now, I've decided to turn my passion into my career.
            <br />
            I believe that if you love what you do, it will never feel like work
            - and that's what I strive for.
            <br />
            <br />
            When I'm not working, I enjoy:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Being a Father
            </li>
            <li className="about-activity">
              <ImPointRight /> Building small circuit projects
            </li>
            <li className="about-activity">
              <ImPointRight /> Woodworking
            </li>
            <li className="about-activity">
              <ImPointRight /> Traveling
            </li>
            <li className="about-activity">
              <ImPointRight /> Building my smart home
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "I have never let my schooling interfere with my education."{" "}
          </p>
          <footer className="blockquote-footer"> Mark Twain </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
