import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../Assets/HNester_Resume2024.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./ResumeNew.css";

// Configure pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc =
  pdfjs.version === "3.4.120"
    ? `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js`
    : `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(window.innerWidth);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
    setIsLoading(false);
  };

  const onDocumentLoadError = (error) => {
    setError(error);
    setIsLoading(false);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>

        <Row className="resume" style={{ position: "relative" }}>
          <div
            className="d-flex flex-column align-items-center"
            style={{ width: "100%" }}
          >
            <div className="mb-3" style={{ zIndex: 1, position: "relative" }}>
              <Button
                variant="primary"
                onClick={previousPage}
                disabled={pageNumber <= 1}
                className="mx-2"
                style={{ cursor: "pointer" }}
              >
                Previous
              </Button>
              <span className="mx-2">
                Page {pageNumber} of {numPages}
              </span>
              <Button
                variant="primary"
                onClick={nextPage}
                disabled={pageNumber >= numPages}
                className="mx-2"
                style={{ cursor: "pointer" }}
              >
                Next
              </Button>
            </div>
            <div className="d-flex justify-content-center pdf-container">
              {error ? (
                <div className="text-center">
                  <p>Error loading PDF: {error.message}</p>
                  <p>Please try downloading the resume instead.</p>
                </div>
              ) : (
                <Document
                  file={pdf}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={onDocumentLoadError}
                  loading={
                    <div className="text-center">
                      <p>Loading PDF...</p>
                    </div>
                  }
                  options={{
                    cMapUrl: "https://unpkg.com/pdfjs-dist@3.4.120/cmaps/",
                    cMapPacked: true,
                  }}
                >
                  {!isLoading && (
                    <Page
                      pageNumber={pageNumber}
                      scale={width > 1200 ? 1.7 : width > 786 ? 1.3 : 0.6}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      loading={
                        <div className="text-center">
                          <p>Loading page...</p>
                        </div>
                      }
                    />
                  )}
                </Document>
              )}
            </div>
          </div>
        </Row>

        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;
