import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.jpg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              A BIT ABOUT <span className="purple">ME</span>
            </h1>
            <p className="home-about-body">
              Solving problems has always brought me the most joy in life.
              <br />
              <br />
              The thrill of fixing something or building something from scratch
              is what gets me out of bed in the morning (and keeps me up late at
              night).
              <br />
              <br />
              My foundational knowledge comes from hardware and electrical
              engineering, which sparked my passion to expand into other
              technologies.
              <br />
              <br />I am proficient in:
              <i>
                <b className="purple"> Python, Bash, and YAML </b>
              </i>
              <br />
              <br />
              My fields of interest include building and maintaining &nbsp;
              <i>
                <b className="purple">servers and large-scale networks</b>,
                which led to my need to become proficient in{" "}
                <b className="purple">coding</b>
              </i>
              .
              <br />
              <br />I believe in making technology work for me, not the other
              way around. This philosophy has led me to build my own{" "}
              <b className="purple">devices</b> using
              <i>
                <b className="purple"> microcontrollers </b>
                and
                <b className="purple"> single-board computers</b>
              </i>
              &nbsp; such as
              <i>
                <b className="purple"> ESP32s, Arduinos,</b>
                and
                <b className="purple"> Raspberry Pis</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>CONNECT WITH ME</h1>
            <p>Let's connect and collaborate!</p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/neuron89"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/hayden-nester/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
